
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarSaleReports from "@/components/sidebar-sale-reports.vue";
import ReportService from "@/services/reports-service";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "../../../../services/filter-service"
import Moment from 'moment'

const moment = require('moment')
const VueQrcode = require('@chenfengyuan/vue-qrcode');

@Component({
  components: {
    Header,
    SidebarSaleReports,
    VueQrcode,
    FilterComponent
  },
})
export default class Sales extends Vue {
  public filter = null;
  public filterOn = [];
  trans = [];
  public value = '2';
  isdetailed = false;
  show_cash = false;
  show_credit = false;
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  public options_project = ['All',
    'Aramco-Riyadh',
    'Aramco-YANBU',
    'Aramco-JUAYMAH',
    'Aramco-Jeddah', 'Yasref', 'Marafiq', 'KJO', 'Bahria','Bahria-New', 'Grain-Sailos', 'Tanajeeb', 'Milling-company',
    'Air-fueling-Makkah',
    'Air-fueling-Riyadh',
    'Air-fueling-Shirqiya',
    'Air-fueling-Aseer',
    'Tameed', 'GEMS'];
  public project = this.options_project[0];
  options_salesaccount = [];
  selected_salesaccount = "";
  selected_report_date_from: any = '';
  selected_report_date_to: any = '';
  options_product = [];
  selected_product = "";

  options_party = [];
  selected_party = "";

  options_branch = [];
  selected_branch = "";

  options_division = [];
  selected_division = "";
  public fields = [
    {
      key: "invoice_id",
      label: "Credit #",
    },
    {
      key: "project",
    },
    {
      key: "customer",
    },
    // {
    //   key: "bank",
    // },
    {
      label: "Reference #",
      key: "ref_invoice",
    },
    {
      key: "po",
      label: "PO #"
    },
    {
      key: "contract_no",
      label: "Contract #"
    },
    {
      key: "total_amount",
    },
    {
      key: "vat_amount",
    },
    {
      key: "retention_amount",
    },
    {
      key: "net_amount",
    },
    {
      key: "created_at",
      label: "Date",
      formatter: (value: any) => {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    {
      key: "created_at",
      label: "Date",
      formatter: (value: any) => {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    {
      key: "Get_invoice"

    },
  ];
  public columns = [
    {
      label: 'credit #',
      field: 'invoice_id',
    },
    {
      label: 'project',
      field: 'project',
    },
    {
      label: 'customer',
      field: 'customer',
    },
    {
      label: 'reference',
      field: 'ref_invoice',
    },
    {
      label: 'po',
      field: 'po',
    },
    {
      label: 'contract_no',
      field: 'contract_no',
    },

    {
      label: 'total_amount',
      field: 'total_amount',
    },
    {
      label: 'vat_amount',
      field: 'vat_amount',
    },
    {
      label: 'retention_amount',
      field: 'retention_amount',
    },
    {
      label: 'net_amount',
      field: 'net_amount',
    },
    {
      label: 'date',
      field: 'created_at',
      dataFormat: (value: any) => {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
  ];

  public items_fields = [
    { key: "item" },
    { key: "price" },
    { key: "quantity" },
    { key: "amount" },

  ];
  get rows() {
    return this.selected_items.length;
  }
  public totalamount = 0;
  public net_amount = 0;
  public gross_amount = 0;
  public vat_amount = 0;
  public retention_amount = 0;

  public itemid = "";
  public invoice: any = {};
  public sales: any = {};
  public items = [];
  public selected_items = [
    {
      customer: "",
      project: "",
      bank: "",
      po: "",
      vendor: "",
      costcenter: "",
      contract_no: "",
      contractor_no: "",
      invoice_type: "",
      created_at: "",
    },
  ];
  public selected_items_master = [
    {
      customer: "",
      project: "",
      bank: "",
      po: "",
      vendor: "",
      costcenter: "",
      contract_no: "",
      contractor_no: "",
      invoice_type: "",
      created_at: "",
    },
  ];

  // changedValue(data:any) {
  //   if(data == 'All'){
  //     this.selected_items = this.selected_items_master  
  //   }else{
  //   let t =  this.selected_items_master.filter(x=> x.project == data)
  //   this.selected_items = t;
  //   }
  // }
  clear() {
    this.selected_report_date_from = '';
    this.selected_report_date_to = '';
    this.project = this.options_project[0];
    this.selected_items_master = []
    this.selected_items = []
  }
  submit() {
    // let obj = {
    //   start_date: this.selected_report_date_from,
    //   end_date: this.selected_report_date_to,
    //   project: this.project,
    // }
    let obj = {
      start_date: this.formatDate(this.selected_report_date_from),
      end_date: this.formatDate(this.selected_report_date_to),
      project: this.project,
    };
    this.callservice(obj)
  }
  callservice(obj: any) {
    ReportService.getallcreditreports(obj)
      .then((response) => {
        this.selected_items_master = response.data.sales;
        this.selected_items = response.data.sales;

      })
      .catch((e) => {
        console.log(e);
      });
  }

  onRowClicked(item: any, index: any, event: any) {
    ReportService.getsalereport(item.id)
      .then((response) => {
        let total = 0;
        this.totalamount = 0;
        this.invoice = response.data;
        this.value = (item.qrcode)

        this.sales = response.data.sales[0];
        response.data.items.forEach((x: any) => {
          total += Number(x.amount);
        });
        this.totalamount = total
        this.vat_amount = this.totalamount * 0.15;
        this.gross_amount = this.vat_amount + this.totalamount;
        this.retention_amount = this.totalamount * 0.10;
        this.net_amount = this.gross_amount - this.retention_amount;
      })
      .catch((e) => {
        console.log(e);
      });
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant");
  }
  mounted() {
    let current = new Date();

    let now_date = current.getFullYear();
    let next_date = current.getFullYear();

    // let start_date = `${current.getFullYear()}-${current.getMonth()}-${current.getDate()}`;
    // this.selected_report_date_from = start_date;

    // let end_date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate() + 1}`;
    // this.selected_report_date_to = end_date;
    let start_date = `${now_date}-${current.getMonth()}-${current.getDate()}`;
    this.selected_report_date_from = start_date;
    let end_date = `${next_date}-${current.getMonth() + 1}-${current.getDate() + 1
      }`;
    this.selected_report_date_to = end_date;

    let obj = {
      start_date: this.formatDate(this.selected_report_date_from),
      end_date: this.formatDate(this.selected_report_date_to),
      project: 'All',
    }
    this.callservice(obj)
  }
  formatDate(date: any) {
    date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  get_pdf_row(getdata: any) {
    console.log(getdata)
    let routeData: any;
    if (getdata.ref_invoice_id) {
      if (getdata.project == 'Aramco-Jeddah') {
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-jeddah-credit/${getdata.id}` });
      }
      if (getdata.project == 'Aramco-Riyadh') {
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-riyadh-credit/${getdata.id}` });
      }
      if (getdata.project == 'Tanajeeb') {
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-tanajeeb-credit/${getdata.id}` });
      }
      if (getdata.project == 'Milling-company') {
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-milling-credit/${getdata.id}` });
      }
      if (getdata.project == 'Mastoura') {
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-aramco-mastoura-credit/${getdata.id}` });
      }
      if (getdata.project == 'Aramco-JUAYMAH') {
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-aramco-juaymah-credit/${getdata.id}` });
      }
      if (getdata.project == 'Bahria-New') {
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-bahria-new-credit/${getdata.id}` });
      }

    } else {
      if (getdata.project == 'Milling-company') {
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-milling-credit/${getdata.id}` });
      } else {
        routeData = this.$router.resolve({ path: `/invoice/sales-credit-jeddah-general/${getdata.id}` });
      }
    }

    window.open(routeData.href, '_blank');

  }

  print() {
    let route: any = null;
    if (this.sales.ref_invoice_id) {
      if (this.sales.project == 'Aramco-Jeddah') {
        route = this.$router.resolve({ path: `/invoice/sales-invoice-jeddah-credit/${this.sales.id}` });
      }
      if (this.sales.project == 'Aramco-Riyadh') {
        route = this.$router.resolve({ path: `/invoice/sales-invoice-riyadh-credit/${this.sales.id}` });
      }
      if (this.sales.project == 'Tanajeeb') {
        route = this.$router.resolve({ path: `/invoice/sales-invoice-tanajeeb-credit/${this.sales.id}` });
      }
      if (this.sales.project == 'Milling-company') {
        route = this.$router.resolve({ path: `/invoice/sales-invoice-milling-credit/${this.sales.id}` });
      }
      if (this.sales.project == 'Mastoura') {
        route = this.$router.resolve({ path: `/invoice/sales-invoice-aramco-mastoura-credit/${this.sales.id}` });
      }
      if (this.sales.project == 'Aramco-JUAYMAH') {
        route = this.$router.resolve({ path: `/invoice/sales-invoice-aramco-juaymah-credit/${this.sales.id}` });
      }
      if (this.sales.project == 'Bahria-New') {
        route = this.$router.resolve({ path: `/invoice/sales-invoice-bahria-new-credit/${this.sales.id}` });
      }
      //  route = this.$router.resolve({ path: `/invoice/sales-invoice-jeddah-credit/${this.sales.id}` });
    } else {
      if (this.sales.project == 'Milling-company') {
        route = this.$router.resolve({ path: `/invoice/sales-invoice-milling-credit/${this.sales.id}` });
      } else {
        route = this.$router.resolve({ path: `/invoice/sales-credit-jeddah-general/${this.sales.id}` });
      }

    }

    window.open(route.href);
  }
  // filter_fields = [
  //   { type: "text", label: "Invoice No.", name: "invoice#", value: "", param_name: "invoice_id", filter_type: "simple" },
  //   // { type: "text", label: "Project", name: "project", value: "", param_name: "project", filter_type: "simple" },
  //   { type: "text", label: "Customer", name: "customer", value: "", param_name: "customer", filter_type: "simple" },
  //   { type: "text", label: "Reference No.", name: "reference#", value: "", param_name: "ref_invoice", filter_type: "simple" },
  //   { type: "text", label: "PO No.", name: "po#", value: "", param_name: "po", filter_type: "simple" },
  //   { type: "text", label: "Contract No.", name: "contract#", value: "", param_name: "contract_no", filter_type: "simple" },
  //   { type: "text", label: "Total Amount", name: "total_amount", value: "", param_name: "total_amount", filter_type: "simple" },
  //   { type: "text", label: "VAT Amount", name: "vat_amount", value: "", param_name: "vat_amount", filter_type: "simple" },
  //   { type: "text", label: "Retention Amount", name: "retention_amount", value: "", param_name: "retention_amount", filter_type: "simple" },
  //   { type: "text", label: "Net Amount", name: "net_amount", value: "", param_name: "net_amount", filter_type: "simple" },
  //   { type: "date", label: "Date", name: "date", value: "", param_name: "date", filter_type: "simple" },
  // ];

  advance_filter_fields = [
    { type: "text", label: "Invoice No.", name: "invoice#", value: "", param_name: "invoice_id", filter_type: "advance" },
    // { type: "text", label: "Project", name: "project", value: "", param_name: "project", filter_type: "advance" },
    { type: "text", label: "Customer", name: "customer", value: "", param_name: "customer", filter_type: "advance" },
    { type: "text", label: "Reference No.", name: "reference#", value: "", param_name: "ref_invoice", filter_type: "advance" },
    { type: "text", label: "PO No.", name: "po#", value: "", param_name: "po", filter_type: "advance" },
    { type: "text", label: "Contract No.", name: "contract#", value: "", param_name: "contract_no", filter_type: "advance" },
    { type: "text", label: "Total Amount", name: "total_amount", value: "", param_name: "total_amount", filter_type: "advance" },
    { type: "text", label: "VAT Amount", name: "vat_amount", value: "", param_name: "vat_amount", filter_type: "advance" },
    { type: "text", label: "Retention Amount", name: "retention_amount", value: "", param_name: "retention_amount", filter_type: "advance" },
    { type: "text", label: "Net Amount", name: "net_amount", value: "", param_name: "net_amount", filter_type: "advance" },
    { type: "date", label: "Date", name: "date", value: "", param_name: "date", filter_type: "advance" },

    { type: "text", label: "Invoice", name: "min_invoice_id", value: "", param_name: "min_invoice_id", filter_type: "advance", placeholder: "minimum" },
    { type: "text", label: "", name: "max_invoice_id", value: "", param_name: "max_invoice_id", filter_type: "advance", placeholder: "maximum" },

    { type: "text", label: "Total Amount", name: "min_total_amount", value: "", param_name: "min_total_amount", filter_type: "advance", placeholder: "minimum" },
    { type: "text", label: "", name: "max_total_amount", value: "", param_name: "max_total_amount", filter_type: "advance", placeholder: "maximum" },

    { type: "text", label: "VAT Amount", name: "min_vat_amount", value: "", param_name: "min_vat_amount", filter_type: "advance", placeholder: "minimum" },
    { type: "text", label: "", name: "max_vat_amount", value: "", param_name: "max_vat_amount", filter_type: "advance", placeholder: "maximum" },

    { type: "text", label: "Retention Amount", name: "min_retention_amount", value: "", param_name: "min_retention_amount", filter_type: "advance", placeholder: "minimum" },
    { type: "text", label: "", name: "max_retention_amount", value: "", param_name: "max_retention_amount", filter_type: "advance", placeholder: "maximum" },

    { type: "text", label: "Net Amount", name: "min_net_amount", value: "", param_name: "min_net_amount", filter_type: "advance", placeholder: "minimum" },
    { type: "text", label: "", name: "max_net_amount", value: "", param_name: "max_net_amount", filter_type: "advance", placeholder: "maximum" },

    // { type: "range", label: "Invoice", min_param_name: "min_invoice_id", max_param_name: "max_invoice_id", value_2: [0, 0], min: 0, max: 50, filter_type: "advance" },
    // { type: "range", label: "Total Amount", min_param_name: "min_total_amount", max_param_name: "max_total_amount", value_2: [0, 0], min: 0, max: 50, filter_type: "advance" },
    // { type: "range", label: "VAT Amount", min_param_name: "min_vat_amount", max_param_name: "max_vat_amount", value_2: [0, 0], min: 0, max: 50, filter_type: "advance" },
    // { type: "range", label: "Retention Amount", min_param_name: "min_retention_amount", max_param_name: "max_retention_amount", value_2: [0, 0], min: 0, max: 50, filter_type: "advance" },
    // { type: "range", label: "Net Amount", min_param_name: "min_net_amount", max_param_name: "max_net_amount", value_2: [0, 0], min: 0, max: 50, filter_type: "advance" },
    { type: "date", label: "Date", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", filter_type: "advance", range: [] },
  ];
  handleFilterChange(element: any) {
    // if (element.filter_type === "simple") {
    //   interface SimpleObj {
    //     [key: string]: any;
    //   }
    //   const simpleFilteredObj: SimpleObj = {};
    //   this.filter_fields.forEach(filter => {
    //     simpleFilteredObj[filter.param_name] = filter.value;
    //   });
    //   FilterService.getAllInvoice(simpleFilteredObj).then((response) => {
    //     if (response.data.sale_order !== undefined && response.data.sale_order !== null) {
    //       this.selected_items = (response.data.sale_order)
    //       console.log(simpleFilteredObj);
    //     }
    //     else {
    //       // const obj = {};
    //       // this.callservice(obj);
    //     }
    //   })
    // }
    // else
    if (element.filter_type === "advance") {
      interface AdvanceObj {
        [key: string]: any;
      }
      const advanceFilteredObj: AdvanceObj = {};
      this.advance_filter_fields.forEach(filter => {
        if (filter.type === "text") {
          if (filter.param_name) {
            advanceFilteredObj[filter.param_name] = filter.value
          }
        }
        if (filter.type === "date") {
          if (filter.min_param_name && filter.range) {
            const date0 = new Date(filter.range[0]);
            const formattedDate0 = Moment(date0).format('YYYY-MM-DD');
            advanceFilteredObj[filter.min_param_name] = formattedDate0 === "Invalid date" ? '' : formattedDate0
          }
          if (filter.max_param_name && filter.range) {
            const date1 = new Date(filter.range[1]);
            const formattedDate1 = Moment(date1).format('YYYY-MM-DD');
            advanceFilteredObj[filter.max_param_name] = formattedDate1 === "Invalid date" ? '' : formattedDate1
          }
        }
        // if (filter.type === "range") {
        //   if (filter.min_param_name && filter.value_2) {
        //     advanceFilteredObj[filter.min_param_name] = filter.value_2[0] === 0 ? "" : filter.value_2[0]
        //   }
        //   if (filter.max_param_name && filter.value_2) {
        //     advanceFilteredObj[filter.max_param_name] = filter.value_2[1] === 0 ? "" : filter.value_2[1]
        //   }
        // }
      });
      if (advanceFilteredObj['min_invoice_id'] != "" && advanceFilteredObj['max_invoice_id'] != "") {

        FilterService.getAllInvoice(advanceFilteredObj).then((response) => {
          if (response.data.sale_order !== undefined && response.data.sale_order !== null) {
            this.selected_items = (response.data.sale_order)
            console.log(advanceFilteredObj);
          }
          else {
            this.submit()
          }
        })
      } else if (advanceFilteredObj['min_total_amount'] != "" && advanceFilteredObj['max_total_amount'] != "") {

        FilterService.getAllInvoice(advanceFilteredObj).then((response) => {
          if (response.data.sale_order !== undefined && response.data.sale_order !== null) {
            this.selected_items = (response.data.sale_order)
            console.log(advanceFilteredObj);
          }
          else {
            this.submit()
          }
        })
      } else if (advanceFilteredObj['min_vat_amount'] != "" && advanceFilteredObj['max_vat_amount'] != "") {

        FilterService.getAllInvoice(advanceFilteredObj).then((response) => {
          if (response.data.sale_order !== undefined && response.data.sale_order !== null) {
            this.selected_items = (response.data.sale_order)
            console.log(advanceFilteredObj);
          }
          else {
            this.submit()
          }
        })
      } else if (advanceFilteredObj['min_retention_amount'] != "" && advanceFilteredObj['max_retention_amount'] != "") {

        FilterService.getAllInvoice(advanceFilteredObj).then((response) => {
          if (response.data.sale_order !== undefined && response.data.sale_order !== null) {
            this.selected_items = (response.data.sale_order)
            console.log(advanceFilteredObj);
          }
          else {
            this.submit()
          }
        })
      } else if (advanceFilteredObj['min_net_amount'] != "" && advanceFilteredObj['max_net_amount'] != "") {

        FilterService.getAllInvoice(advanceFilteredObj).then((response) => {
          if (response.data.sale_order !== undefined && response.data.sale_order !== null) {
            this.selected_items = (response.data.sale_order)
            console.log(advanceFilteredObj);
          }
          else {
            this.submit()
          }
        })
      } else {
        FilterService.getAllInvoice(advanceFilteredObj).then((response) => {
          if (response.data.sale_order !== undefined && response.data.sale_order !== null) {
            this.selected_items = (response.data.sale_order)
            console.log(advanceFilteredObj);
          }
          else {
            this.submit()
          }
        })
      }

    }
    console.log()
  }

  // updateFilterFields(fields: any[]) {
  //   this.filter_fields = fields;
  // }
  refreshAdvanceFilters() {
    // for (let field of this.filter_fields) {
    //   field.value = "";
    //   const obj = {};
    //   this.callservice(obj);
    // }
    for (let field of this.advance_filter_fields) {
      if (field.type == 'text') {
        field.value = "";
      } else if (field.type == "date") {
        field.range = [];
      }
      // else if (field.type == 'range') {
      //   field.value_2 = [0, 0]
      // }
      this.submit()

    }
  }
}
