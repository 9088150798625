import { render, staticRenderFns } from "./invoices-credit-note.vue?vue&type=template&id=0bdd6d01&scoped=true"
import script from "./invoices-credit-note.vue?vue&type=script&lang=ts"
export * from "./invoices-credit-note.vue?vue&type=script&lang=ts"
import style0 from "./invoices-credit-note.vue?vue&type=style&index=0&id=0bdd6d01&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bdd6d01",
  null
  
)

export default component.exports